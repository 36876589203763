<template>
  <div id="account-join">
    <div v-if="allowJoin">
      <h1 aria-label="Create an Aira Account">
        Create an Aira Account
      </h1>
      <b-form class="form-container">
        <FormProgressBar :progress="progress()" />
        <FormErrors />
        <div class="form">
          <FormSection
            description="In order to share the minutes of another Aira user, you must create your own Aira account.
                    With Aira, you will be able to connect with an Aira agent who will provide you information about your
                    surrounding area. Once created, download the Aira app and connect with an agent instantly!"
          />
          <FormSection
            title="Basic Information"
          >
            <FormGroup
              for="firstName"
              label="First Name"
              class="w-100"
            >
              <FormInput
                bind-id="firstName"
                required
                get="firstName"
                set="UPDATE_FIRST_NAME"
                placeholder="First Name"
              />
            </FormGroup>
            <FormGroup
              for="lastName"
              label="Last Name"
              class="w-100"
            >
              <FormInput
                bind-id="lastName"
                required
                get="lastName"
                set="UPDATE_LAST_NAME"
                placeholder="Last Name"
              />
            </FormGroup>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="countryCode"
                label="Country"
                class="w-25-35"
              >
                <FormSelect
                  :options="countryCodeOptions"
                  bind-id="countryCode"
                  default="+1"
                  get="countryCode"
                  set="UPDATE_COUNTRY_CODE"
                  required
                />
              </FormGroup>
              <FormGroup
                for="phoneNumber"
                label="Phone Number (Starts with area code)"
                class="w-75-65"
              >
                <FormInput
                  aria-label="phone number"
                  type="tel"
                  bind-id="phoneNumber"
                  get="phoneNumber"
                  set="UPDATE_PHONE_NUMBER"
                  placeholder="Mobile Number"
                />
              </FormGroup>
            </div>
          </FormSection>
          <FormSection
            title="Account Information"
          >
            <FormGroup
              for="email"
              label="Email"
              class="w-100"
            >
              <FormInput
                :disabled="emailDisabled"
                :placeholder="userEmail"
                bind-id="email"
                type="email"
                name="Email"
                get="email"
                set="UPDATE_EMAIL"
              />
            </FormGroup>
            <FormGroup
              for="password"
              label="Password"
              tip="At least 8 characters"
              class="w-100 tip"
            >
              <FormInput
                bind-id="password"
                type="password"
                name="Password"
                get="password"
                set="UPDATE_PASSWORD"
                placeholder="Password"
              />
            </FormGroup>
          </FormSection>
        </div>
        <b-button
          variant="primary"
          @click="handleSubmitRedirect"
        >
          Submit
        </b-button>
      </b-form>
    </div>
    <div v-if="allowJoin === null">
      <h1>Loading</h1>
    </div>
    <div
      v-if="allowJoin === false"
      class="mx-5"
    >
      <h1>Invalid Invite Code</h1>
      <p class="pb-4">
        The invite link has expired. Please ask the primary plan holder to resend
        an invite or <a href="aira.io/pricing">click here</a> to setup your own plan. If you
        believe this to be a mistake, please call us at <a href="tel:1-800-835-1934">1-800-835-1934</a>, or drop a note
        with your phone number at <a
          target="_top"
          href="mailto:your@email.address?subject=Comments about the color blue"
        > support@aira.io</a> and we will get back to you.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateEmail } from '../utils/validation';
import ValidationMixin from '../mixin/ValidationMixin';
import AiraError from '../utils/AiraError';

import FormSection from './Form/FormSection.vue';
import FormGroup from './Form/FormGroup.vue';
import FormInput from './Form/FormInput.vue';
import FormErrors from './Form/FormErrors.vue';
import FormSelect from './Form/FormSelect.vue';
import FormProgressBar from './Form/FormProgressBar.vue';

export default {
  name: 'JoinAccount',
  components: {
    FormSection,
    FormGroup,
    FormInput,
    FormErrors,
    FormSelect,
    FormProgressBar,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      allowJoin: null,
      emailDisabled: true,
      userEmail: null,
    };
  },
  computed: {
    ...mapGetters([
      'email',
      'firstName',
      'lastName',
      'password',
      'email',
      'errors',
      'phoneNumber',
      'countryCode',
      'countryCodeOptions',
    ]),
  },
  created() {
    this.checkCode();
  },
  methods: {
    async checkCode() {
      try {
        const codeVerify = await this.$store.dispatch('joinAccountVerifyCode', { inviteCode: this.$route.query.code });
        this.allowJoin = true;
        console.debug('Verification response: ', JSON.stringify(codeVerify));
        this.userEmail = codeVerify.invitee;
        this.$store.commit('UPDATE_EMAIL', codeVerify.invitee);
      } catch (e) {
        console.error('checkCode resp', e);
        this.allowJoin = false;
      }
    },
    progress() {
      return { full: true };
    },
    async handleSubmitRedirect() {
      this.$store.commit('CLEAR_ERRORS');
      let errors = [];
      const fieldErrors = [];

      if (!this.firstName) fieldErrors.push('First Name');
      if (!this.email) fieldErrors.push('Email');
      if (!this.phoneNumber) fieldErrors.push('Phone Number');
      if (this.phoneNumber && this.phoneNumber.length < 7) fieldErrors.push('Phone number not valid');
      if (this.email && !validateEmail(this.email)) fieldErrors.push('Email not valid');
      if (this.password.length < 8) fieldErrors.push('Password');

      errors = [...errors, ...fieldErrors];

      console.debug('Errors: ', JSON.stringify(errors));

      this.$store.commit('PUSH_ERRORS', errors);

      if (!this.errors.length) {
        try {
          const response = await this.$store.dispatch('joinAccountCreateUser', this.$route.query.code);
          console.debug('Join account create user response: ', JSON.stringify(response));
          window.location.href = 'https://aira.io/get-started-confirmation';
        } catch (e) {
          const airaError = new AiraError(e);
          this.$store.commit('PUSH_ERRORS', airaError.errorMessage);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables/_colors.scss';
@import '../styles/variables/_sizes.scss';
@import '../styles/theme/_default.scss';

#account-join {
    margin-top: 50px;
    .form {
        padding: 20px 20px 0px 20px;
    }
}

</style>
